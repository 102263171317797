import {isEmpty, map} from "lodash";

import {convertToLocale} from "../../lib/utils";
import CONSTANT from "./constant";
import moment from "moment";

export const getSMSBalance = ({smsTypeTaken, smsCount}) => smsTypeTaken.map((el, index) => (
  {
    id: index,
    value: smsCount[el],
    label: `${CONSTANT.SMS_TYPE_MAP[el].name} - ${convertToLocale(smsCount[el])}`,
    color: CONSTANT.SMS_TYPE_MAP[el].color

  }))

  
const getDays = (key, selectedDuration) => {
  const original = key
  const keySplit = key.split('::')
  if (selectedDuration === 'days')
    return {key:keySplit.slice(0, 2).join(' '),original}
  if (selectedDuration === 'months') {
    return {key:keySplit[1],original}
  }
  if (selectedDuration === 'hours') {
    return {key:moment().startOf('day').hours(parseInt(keySplit[0]) + 1),original:moment().startOf('day').hours(parseInt(keySplit[0]) + 1)}
  }
  return {key,original}
}

export const getSMSUsage = (data, selectedDuration, smsType, senderId) => {
  let result = !isEmpty(data?.[selectedDuration]) ? map(data?.[selectedDuration], (value, key) => {
    let submitted = 0
    value?.sent?.messagetype?.forEach(el => {
      if (smsType?.includes(el?.name)) {
        el?.breakup?.forEach(innerEl => {
          if (senderId?.includes(innerEl?.name)) {
            submitted += parseInt(innerEl.total)
          }
        })
      }
    })
    let delivered = 0
    value?.received?.messagetypeSenderid?.forEach(el => {
      if (smsType?.includes(el?.name)) {
        el?.breakup?.forEach(innerEl => {
          if (senderId?.includes(innerEl?.name)) {
            delivered += parseInt(innerEl.breakup?.DELIVERY_SUCCESS ?? 0)
          }
        })
      }
    })
    if (delivered>submitted){
      delivered = submitted
    }
    const formatted_key = getDays(key, selectedDuration)
    return {
      name: formatted_key.key,
      original: formatted_key.original,
      submitted,
      delivered
    }
  })
    : []
  result = result.sort((a, b) => moment(a.name).diff(b.name))
  if (selectedDuration === 'months') {
    return result.sort((a, b) => moment(a.original).diff(b.original))
  }
  if (selectedDuration === 'hours') {
    return result.map(el => ({...el, name: moment(el.name).format('hh A')}))
  }
  return result.sort((a, b) => moment(a.name).diff(b.name))
}